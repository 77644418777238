// Basics
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VeeDictionary from './settings/vee-dictionary';
import Barba from 'barba.js';
import 'cookieconsent/src/cookieconsent';

// Mixins
import mixinBlazy from './mixins/blazy';
import mixinScroll from './mixins/scroll';
import mixinViewportheight from './mixins/viewportheight';
import mixinDatafilled from './mixins/datafilled';

// Settings
import barba_basic_fadetransition from './settings/barba_basic_fadetransition';

// Global
let Global;

// Vue plugins
Vue.use(VeeValidate, {
    locale: document.documentElement.lang,
    events: 'blur|submit|change',
    classes: true,
    invalidateFalse: true,
    classNames: {
        invalid: 'input--invalid',
        valid: 'input--valid'
    },
    dictionary: VeeDictionary,
});

// Na inladen DOM inhoud
document.addEventListener("DOMContentLoaded", function () {
    document.querySelector('body').classList.add('body--loaded');

    Barba.Pjax.getTransition = function () {
        return Barba.BaseTransition.extend(barba_basic_fadetransition);
    };
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();

    let modal = document.getElementById("notificationModal");
    if (modal) {
        modal.classList.add('modal__active');
        document.documentElement.style.overflowY = 'hidden';

        let modalClose = document.getElementsByClassName("modal__close")[0];
        modalClose.onclick = function () {
            closeModal();
        }

        if (window.location.href.indexOf("open-dagen") > -1) {
            closeModal()
        }

        let link = document.getElementById('notificationModalClose');
        link.onclick = function () {
            closeModal();
        }

        window.onclick = function(event) {
            if (event.target == modal) {
                closeModal();
            }
        }
    }

    function closeModal () {
        modal.classList.remove('modal__active');
        document.documentElement.style.overflowY = 'auto';
    }
});

// Na transitie door Barba
Barba.Dispatcher.on("transitionCompleted", function () {
    // Vue root
    Global = new Vue({
        el: document.getElementById("app"),
        mixins: [
            mixinBlazy,
            mixinScroll,
            mixinViewportheight,
            mixinDatafilled,
        ],
        components: {
            'header-component': () => import('./components/header-component'),
            'form-component': () => import('./components/form-component'),
            'collaboration-component': () => import('./components/collaboration-component'),
            'card-wide-component': () => import('./components/card-wide-component'),
        },
    })
});

// Na inladen pagina door Barba
Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {
    let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
        match = regexp.exec(newPageRawHTML);
    if(!match || !match[1]) return;
    document.body.setAttribute('class', match[1]);

    /**
     * Toevoeging voor het correct tracken in analytics
     *
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
     */

    if (Barba.HistoryManager.history.length <= 1) {
        return;
    }

    // send statics by Google Analytics(analytics.js) or Google Tag Manager
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
        var trackers = ga.getAll();
        trackers.forEach(function (tracker) {
            ga(tracker.get('name') + '.set', 'page', location.pathname);
            ga(tracker.get('name') + '.send', 'pageview');
        });
    }
});

// Na verandering link door Barba
Barba.Dispatcher.on('initStateChange', function () {
    if(Global) {
        Global.$destroy();
    }
});
