import MoveTo from 'moveto';

export default {
    data() {
        return {
            MoveToInstance: new MoveTo({
                tolerance: this.getTolerance(),
                duration: 800
            }),
        }
    },
    methods: {
        getTolerance() {
            let headerHeight = 0;
            if (document.querySelector('header')) {
                headerHeight = document.querySelector('header').clientHeight;
            }

            return headerHeight;
        },
        SmoothScroll() {
            const that = this;

            document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                if (anchor.getAttribute('href').length > 1) {
                    anchor.addEventListener('click', function (e) {
                        e.preventDefault();

                        // Get target by ID
                        let target = document.querySelector(this.getAttribute('href'));

                        // No result for ID? Check name (without #)
                        if(!target) {
                            let cleanName = this.getAttribute('href').slice(1);
                            target = document.querySelector('[name=' + cleanName + ']');
                        }

                        that.MoveToInstance.options.tolerance = that.getTolerance();
                        that.MoveToInstance.move(target);
                    });

                }
            });
        },
        ScrollTo(target){
            if(typeof target === "string") {
                target = document.querySelector(target);
            }

            this.MoveToInstance.options.tolerance = this.getTolerance();
            this.MoveToInstance.move(target);
        },
    },
    mounted() {
        this.SmoothScroll();
    },
}